<template>
    <TradeShowGrid
        v-editable="blok"
        :data="tradeShowGridData"
        class="py-16 lg:py-28"
    />
</template>

<script setup lang="ts">
import LinkType from '~/types/LinkType';
import TradeShowGrid from '~/components/page-building/tradeshow-grid/TradeShowGrid.vue';
import TradeShowGridType from '~/types/TradeShowGridType';
import TradeShowType from '~/types/TradeShowType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const tradeShowGridData = computed<TradeShowGridType>(() => {
    const tradeShows = props.blok.trade_shows
        ? props.blok.trade_shows.map((tradeShow: any) => {
              return {
                  startDate: tradeShow.start_date,
                  endDate: tradeShow.end_date,
                  location: tradeShow.location,
                  title: tradeShow.title,
                  subtitle: tradeShow.subtitle,
                  link: tradeShow.link
                      ? ({
                            url: tradeShow.link.url,
                        } as LinkType)
                      : null,
                  vEditable: tradeShow,
              } as TradeShowType;
          })
        : [];

    return {
        heading: props.blok.heading,
        items: tradeShows,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    } as TradeShowGridType;
});
</script>
